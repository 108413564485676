<template>
  <div id="mian">
    <div>
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-input
              v-model="query.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="商户名称/用户名"
            ></el-input>
          </div>
          <div class="item itemTime">
            <i>登录时间</i>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>登录时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>商户编号</p>
            </td>
            <td>
              <p>用户名</p>
            </td>
            <td>
              <p>登录IP</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.loginTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchId }}</p>
            </td>
            <td>
              <p>{{ v.username }}</p>
            </td>
            <td>
              <p>{{ v.loginIp }}</p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50,100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import { getLogs } from "@/api/management/index.js";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      query: {
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        pageNo: 1,
        pageSize: 10,
        keyword: "",
      },
      totalNum: 0,
      tabData: [],
    };
  },
  computed: {
    ...mapState({
      logsQueryDetail: "system_logsQuery",
    }),
  },
  created() {
    if (this.logsQueryDetail) {
      this.query = this.logsQueryDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },

  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setLogsQuery(data);
  },
  methods: {
    ...mapMutations({
      setLogsQuery: "system_setLogsQuery",
    }),
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        this.query.keyword = this.query.keyword.trim();
      }

      getLogs(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.logs;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
};
</script>
<style scoped>
.con_from .lineP .itembtn{
  width:20%;
}
.con_from .lineP .item{
  margin-left: 2%;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
</style>
